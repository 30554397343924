import { useTranslation } from 'react-i18next';
import { Button } from 'antd';
import { DoubleLeftIcon, LeftIcon, RightIcon, DoubleRightIcon } from './Icons'

function GridPagination(props) {
    const { t } = useTranslation("DataGrid");
    
    return <div className="pagination">
        <Button title={t('lblPagingFirst')} className="button pager" icon={<DoubleLeftIcon class="button-icon" title={t('lblPagingFirst')} />} block={false} disabled={!props.canPreviousPage} onClick={() => props.gotoPage(0)} />
        <Button title={t('lblPagingPrevious')} className="button pager" icon={<LeftIcon class="button-icon" title={t('lblPagingPrevious')} />} block={false} disabled={!props.canPreviousPage} onClick={() => props.previousPage()} />
        <Button title={t('lblPagingNext')} className="button pager" icon={<RightIcon class="button-icon" title={t('lblPagingNext')} />} block={false} disabled={!props.canNextPage} onClick={() => props.nextPage(0)} />
        <Button title={t('lblPagingLast')} className="button pager" icon={<DoubleRightIcon class="button-icon" title={t('lblPagingLast')} />} block={false} disabled={!props.canNextPage} onClick={() => props.gotoPage(props.pageCount - 1)} />
    </div>;
};

export default GridPagination;
