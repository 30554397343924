import { createContext } from 'react';
import { createBrowserRouter, Navigate, Route, Routes, RouterProvider } from 'react-router-dom';
import usePing, {User} from './Auth/usePing';
import useProfile, { UserProfile, UserProfileLossDraftsClaims, UserProfileLossDraftsClaim } from './Auth/useProfile';
import Layout from './components/Layout';
import PrivateRoute from './components/PrivateRoute';
import Home from './components/Home';
import Test from './components/Test';
import Alerts from './components/Alerts';
import Faq from './components/Faq';
import Profile from './components/Profile';
import Claims from './components/Claim/Claims';
import ErrorBoundary from './components/ErrorBoundary';
import Waiting from './components/Waiting';
import { useTranslation } from 'react-i18next';
import { ConfigProvider } from 'antd';

interface IAuthContextProps {
  accessToken: string;
  extendSession: () => void;
  isAuthenticated: boolean;
  handleLogin: () => void;
  handleLogout: () => void;
  user: User;
}

interface IProfileContextProps {
  userProfile: UserProfile;
  refreshUserProfile: () => void;
  userProfileLossDraftsClaims: UserProfileLossDraftsClaims;
  userProfileLossDraftsClaim: UserProfileLossDraftsClaim;
  changeUserProfileLossDraftsClaim: (claimId: number) => void;
  languagePreference: string;
  changeLanguagePreference: (newLanguageCode: string) => void;
}

export const AuthContext = createContext({} as IAuthContextProps);
export const ProfileContext = createContext({} as IProfileContextProps);

function App() { 
  const { accessToken, extendSession, handleLogin, handleLogout, isAuthenticated, loading, user } = usePing();
  const { userProfile, refreshUserProfile, userProfileLossDraftsClaims, userProfileLossDraftsClaim, changeUserProfileLossDraftsClaim, queryParamsProcessed, languagePreference, changeLanguagePreference } = useProfile(user.id, accessToken);
  const { t } = useTranslation('App');

  const router = createBrowserRouter([
    { path: "*", Component: Root },
  ]);
  
  function Root() {
    return (
      <div className="App">
         <ConfigProvider
            theme={{
                components: {
                    Button: {
                        "colorPrimaryHover": "rgb(0, 101, 204)",
                        "defaultHoverColor": "rgb(0, 101, 204)",
                    }
                },
            }}
        >
        <AuthContext.Provider value={{accessToken, extendSession, isAuthenticated, handleLogin, handleLogout, user}}>
        <ProfileContext.Provider value={{userProfile, refreshUserProfile, userProfileLossDraftsClaims, userProfileLossDraftsClaim, changeUserProfileLossDraftsClaim, languagePreference, changeLanguagePreference}}>
        <ErrorBoundary accessToken={accessToken} user={user}>
          <Routes>
            <Route element={<Layout />}>
              <Route path="/" element={
                isAuthenticated ? <Navigate to={ (userProfileLossDraftsClaims.hasClaims ? "alerts" : "claims") } replace={true} /> : <Home />
              } />
              <Route Component={PrivateRoute}>
                <Route path="alerts" Component={Alerts} />
                <Route path="claims/*" Component={Claims}/>
                <Route path="profile" Component={Profile}/>
                <Route path="faq" Component={Faq}/>
                <Route path="test" Component={Test}/>
                <Route path="*" element={<div>{t('lbl404')}</div>} />
              </Route>
            </Route>
          </Routes>
        </ErrorBoundary>
        </ProfileContext.Provider>
        </AuthContext.Provider>
        </ConfigProvider>
      </div>
    );
  }

  if (loading || 
      // some timing issues with accessToken/user.id were causing the initial screen to be loaded multiple times
      // the below check helps eliminate those loads by displaying the Loading component while the state is set
      (isAuthenticated && (accessToken === "" || user.id === "" || !userProfile.hasLoaded || !userProfileLossDraftsClaims.hasLoaded || !queryParamsProcessed))
    ) {
    return (
      <Waiting size='large' />
    );
  }

  return <RouterProvider router={router} />; 
}

export default App;