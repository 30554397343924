import { useTranslation } from 'react-i18next';
import FaqBody from './FaqBody';

function Home(props) {
    const { t } = useTranslation('Home');
    
    let title = <h1>{t('titleLeft')}<span className='light-blue'>{t('titleMiddle')}</span>{t('titleRight')}</h1>;
    let subtitle = <h4>{t('subtitle')}</h4>
    return <div>{title}{subtitle}<br /><FaqBody /></div>;
  }

export default Home;