import * as React from "react";
const SvgQuestion = (props) => /* @__PURE__ */ React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", id: "question_svg__Layer_1", "data-name": "Layer 1", viewBox: "0 0 250 250.22", width: "1em", height: "1em", ...props }, /* @__PURE__ */ React.createElement("defs", null, /* @__PURE__ */ React.createElement("style", null, ".question_svg__cls-4{stroke-width:0;fill:#fff}")), /* @__PURE__ */ React.createElement("path", { d: "M0 0h250v250H0z", style: {
  fill: "#abc7ca",
  strokeWidth: 0
} }), /* @__PURE__ */ React.createElement("path", { d: "m46.9 202.79 75.06 47.43H250V88.05l-47.02-40.61-83.92 8.41h-14.92l-33.6 33.53-.3 67.21-1.96 24.38z", style: {
  strokeWidth: 0,
  fill: "#8eb6be"
} }), /* @__PURE__ */ React.createElement("path", { d: "M52.71 52.71h144.57v144.57H52.71z", style: {
  stroke: "#fff",
  strokeMiterlimit: 10,
  strokeWidth: "11.26px",
  fill: "#abc7ca"
} }), /* @__PURE__ */ React.createElement("path", { d: "M118.94 145.51c-1.26 0-2.28-1.02-2.28-2.28 0-8.09 2.62-15.28 13.92-25.34 7.88-7.07 9.37-12.47 9.37-18.27 0-6.72-4.17-14.07-14.45-14.07-9.55 0-14.2 5.64-15.9 14.02a2.28 2.28 0 0 1-2.23 1.84h-9.59c-1.39 0-2.47-1.23-2.26-2.6 1.49-9.84 8.61-24.88 30.25-24.82 21.67.07 28.71 14.87 28.71 25.94 0 9.95-3.22 16.5-13.82 26.14-7.05 6.45-9.32 11.4-9.32 18.15 0 0-.25 1.28-1.35 1.28h-11.07Z", className: "question_svg__cls-4" }), /* @__PURE__ */ React.createElement("circle", { cx: 125, cy: 167.09, r: 8.91, className: "question_svg__cls-4" }));
export default SvgQuestion;
