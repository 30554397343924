import React, { useContext, useEffect, useState } from 'react';
import { AuthContext, ProfileContext }  from '../../App';
import { useTranslation } from 'react-i18next';
import { renderColumnHeader, useTable, usePagination, useSortBy, useExpanded } from '../Grid';
import { Button } from 'antd';
import Waiting from '../Waiting';
import {CacheKey, TimedLocalCache}  from '../../util/Cache';
import { handleGetFileResponse }  from '../../util/File';
import { formatDate } from '../../util/Format';
import {get, postJson} from '../../util/HttpRequest'
import { ErrorNotification } from '../Notification';
import GridSummary from '../GridSummary';
import GridPagination from '../GridPagination';
import ClaimDocumentUpload from './DocumentUpload';
import { FilePdfIcon, UploadIcon, SignatureIcon } from '../Icons';

class ClaimTaskData {
    claimTaskId: number = 0;
    claimId: number = 0;
    claimEventCreatedDateUTC: string = "";
    clientEventDescription: string = "";
    clientEventDocumentType: string = "";
    claimEventStatusCode: string = "";
    claimEventStatusDescription: string = "";
    claimEventId: number = 0;
    allowUpload: boolean = false;
    allowESignature: boolean = false;
    docuSignRequestId: number|null = null;
    docuSignEnvelopeId: string|null = null;
};

class ClaimTasksData {
    recordCount: number = 0;
    refreshDate: Date = new Date();
    searchResults: ClaimTaskData[] = [];
};

function ClaimTasks(props) {
    const [loading, setLoading] = useState(true);
    const [errorMsg, setErrorMsg] = useState("");
    const [gridData, setGridData] = useState(new ClaimTasksData());
    const {accessToken, extendSession, user} = useContext(AuthContext);
    const {languagePreference, userProfileLossDraftsClaim} = useContext(ProfileContext);
    const [loadingDocuSign, setLoadingDocuSign] = useState(false);
    const [documentType, setDocumentType] = useState(false);
    const [claimEventId, setClaimEventId] = useState(false);
    const [procedurePacket, setProcedurePacket] = useState(false);
    const { t } = useTranslation(["ClaimTasks", "DataGrid"]);
    const columnHeaderProps = [
        {
            id: 'claimEventCreatedDateUTC', className: 'datagrid-header-left', sortedDescLabel: t('DataGrid:lblSortedDescending'), sortedAscLabel: t('DataGrid:lblSortedAscending'), unsortedLabel: t('DataGrid:lblUnsorted')
        },
        {
            id: 'clientEventDescription', className: 'datagrid-header-left', sortedDescLabel: t('DataGrid:lblSortedDescending'), sortedAscLabel: t('DataGrid:lblSortedAscending'), unsortedLabel: t('DataGrid:lblUnsorted')
        },
        {
            id: 'claimEventStatusDescription', className: 'datagrid-header-left', sortedDescLabel: t('DataGrid:lblSortedDescending'), sortedAscLabel: t('DataGrid:lblSortedAscending'), unsortedLabel: t('DataGrid:lblUnsorted')
        }
    ];

    useEffect (() => {
        refreshData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [languagePreference, userProfileLossDraftsClaim.claimId, props.isClaimActive]);

    function refreshData() {
        setLoading(true);
        getData();
    };

    function getData() {
        extendSession();
        
        if (!props.isClaimActive) {
            setGridData(new ClaimTasksData());
            setErrorMsg("");
            setLoading(false);
            getProcedurePacketInfo();
            return;
        }
        
        const url = new URL(import.meta.env.VITE_CCP_API_CCP + "/ClaimTasks");
        url.searchParams.set("UserId", user.id);
        url.searchParams.set("ClaimId", userProfileLossDraftsClaim.claimId.toString());

        console.log("Fetch Claim Tasks from " + url);

        get(url, accessToken)
        .then( response => {
            if (!response.ok) { throw response }
            return response.json() as Promise<ClaimTaskData[]>
        })
        .then( searchResults => {
            if (searchResults.length > 0) {
                const completedDocuSignRequestIds = TimedLocalCache.getItem(CacheKey.COMPLETED_DOCUSIGN_REQUEST_ID)?.split(",");
                searchResults.filter(obj => obj.allowESignature === true).forEach(obj => {
                    
                    // Sometimes a document is eligible for signature but DS just hasn't created the envelope yet
                    // We want to give the user a message saying it will be ready soon
                    if (obj.docuSignRequestId !== null && obj.docuSignEnvelopeId === null) {
                        obj.allowESignature = false;
                        obj.claimEventStatusDescription = t("statusESignaturePreProcess");
                    }
                    
                    // After an embedded signing, it may take a few seconds/minutes for the status update from DS
                    // We want to give the user a message saying it will be marked completed soon
                    if (completedDocuSignRequestIds && completedDocuSignRequestIds.length > 0) {
                        if (completedDocuSignRequestIds.indexOf(obj.docuSignRequestId?.toString()) !== -1) {
                            obj.allowESignature = false;
                            obj.claimEventStatusDescription = t("statusESignaturePostProcess");
                        }
                    }
                });
            }
            
            const data = {
              recordCount: searchResults.length, 
              refreshDate: new Date(),
              searchResults: searchResults
            };
            setGridData(data);
            setErrorMsg("");
            setLoading(false);
            getProcedurePacketInfo();
        })
        .catch( error => {
            setGridData(new ClaimTasksData());
            setErrorMsg(error.message);
            setLoading(false);
        })
    };

    function getProcedurePacketInfo() {
        extendSession();
        
        const url = new URL(import.meta.env.VITE_CCP_API_CCP + "/DoesClaimHaveProcedurePacket");
        url.searchParams.set("UserId", user.id);
        url.searchParams.set("ClaimId", userProfileLossDraftsClaim.claimId.toString());

        console.log("Check Claim Procedure Packet from " + url);

        get(url, accessToken)
        .then( response => {
            if (!response.ok) { throw response }
            return response.json() as Promise<boolean>
        })
        .then( searchResults => {
            setProcedurePacket(searchResults);
        })
        .catch( error => {
            setProcedurePacket(false);
        })
    };

    function getProcedurePacketFile(isDownload = false) {
        extendSession();
        
        const url = new URL(import.meta.env.VITE_CCP_API_CCP + "/ClaimProcedurePacketFile");
        url.searchParams.set("UserId", user.id);
        url.searchParams.set("ClaimId", userProfileLossDraftsClaim.claimId.toString());

        console.log("Fetch Claim Procedure Packet File from " + url);
        
        get(url, accessToken)
        .then((response) => {
            handleGetFileResponse(response, isDownload);
        })
        .catch( error => {
            setErrorMsg(error.message);
        });
    };

    function handleDocumentUpload(event, curDocumentType, curClaimEventId) {
        if (event) {
            event.preventDefault();
        }
        extendSession();
        setDocumentType(curDocumentType);
        setClaimEventId(curClaimEventId);
        props.showUploadDocumentModal();
    }

    function handleDocumentSigning(event, docuSignRequestId, claimId, docuSignEnvelopeId) {
        event.preventDefault();
        extendSession();
        
        setLoadingDocuSign(true);
        const url = new URL(import.meta.env.VITE_CCP_API_CCP + "/EmbeddedCeremony");
        console.log("Request embedded ceremony from " + url);

        const data = {
            DocuSignEnvelopeId: docuSignEnvelopeId,
            DocuSignRequestId: docuSignRequestId,
            SourceSystemCode: 'LD',
            SSOUserId: user.id
        };

        postJson(url, accessToken, JSON.stringify(data))
        .then((response) => {
            if (!response.ok) { throw new Error(response.status + " - " + response.statusText)}
            return response.text();
        })
        .then(response => {
            // the expected URL in our response should not contain any characters that need encoding
            // so if the encoded response URL is different from the original response URL, we may have XSS
            const hostname = (new URL(response)).hostname.toLowerCase();
            if (response === encodeURI(response) &&
                hostname.endsWith(import.meta.env.VITE_DOCUSIGN_BASE_HOSTNAME||"")
            ) {
                window.location.href = response;
            }
            else {
                setLoadingDocuSign(false);
                ErrorNotification("Error", "Unable to start signing ceremony");
            }
        })
        .catch(error => {
            setLoadingDocuSign(false);
            ErrorNotification("Error", error.message);
        })
        ;
    };

    function Table({ columns, data }) {
        const pageSize = Number(import.meta.env.VITE_GRID_PAGE_SIZE);
        // Use the state and functions returned from useTable to build your UI
        const {
            getTableProps,
            getTableBodyProps,
            headerGroups,
            //rows,
            prepareRow,
            //visibleColumns,
            page, // Instead of using 'rows', we'll use page, which has only the rows for the active page
            canPreviousPage,
            canNextPage,
            // pageOptions,
            pageCount,
            gotoPage,
            nextPage,
            previousPage,
            //setPageSize,
            state: { pageIndex/*, pageSize, expanded*/ },
        } = useTable({
            columns,
            data,
            disableSortRemove: true,
            initialState: { 
                pageIndex: 0,
                pageSize: pageSize
                },
            },
            useSortBy,
            useExpanded, // We can useExpanded to track the expanded state for sub components too!
            usePagination
        )

        const pagination = <GridPagination canPreviousPage={canPreviousPage} canNextPage={canNextPage} 
            pageIndex={pageIndex} pageCount={pageCount} gotoPage={gotoPage} previousPage={previousPage}
            nextPage={nextPage} />;
        
        const summary = <GridSummary pageIndex={pageIndex} pageCount={pageCount} pageSize={pageSize} recordCount={data.length} 
            recordType={t('gridRecordType', {count: data.length})} refreshDate={gridData.refreshDate} pagination={pagination} />;

        return (
        <>
          {summary}
          <table className="table" role="presentation" {...getTableProps()}>
              <thead>
                    {headerGroups.map(headerGroup => {
                        let { ['key']: headerGroupPropsKey, ...headerGroupProps } = headerGroup.getHeaderGroupProps();
                        return (
                            <tr key={headerGroupPropsKey} {...headerGroupProps}>
                                {headerGroup.headers.map(column => (
                                    renderColumnHeader(column, columnHeaderProps.find(x => x.id === column.id))
                                ))}
                            </tr>
                        );
                    })}
                </thead>
              <tbody {...getTableBodyProps()}>
              {page.map((row, i) => {
              prepareRow(row)              
              return (
                  <React.Fragment key={{...row.getRowProps()}.key}>
                    <tr className={"row-" + (i % 2 === 0 ? "even" : "odd")}>
                        {row.cells.map(cell => {
                            let { ['key']: cellPropsKey, ...cellProps } = cell.getCellProps();
                            return (    
                                <td key={cellPropsKey} {...cellProps}>{cell.render('Cell')}</td>
                            )
                        })}
                    </tr>
                  </React.Fragment>
              )
              })}
          </tbody>
          </table>
        </>
      )
  }

    function renderDataGrid() {
        const columns = [{
            id: 'actions',
            sortable: false,
            Cell: row => (
                <div className="datagrid-data-center">
                {
                    row.row.original.allowUpload && (
                        <Button type="link" className="icon-button" title={t('actionUpload')} 
                            onClick={(e) => handleDocumentUpload(e, row.row.original.clientEventDocumentType, row.row.original.claimEventId)}
                        >
                            <UploadIcon title={t('actionUpload')} />
                        </Button>
                    )
                }
                {
                    row.row.original.allowESignature && (
                        <Button type="link" className="icon-button" title={t('actionSign')} 
                            onClick={(e) => handleDocumentSigning(e, row.row.original.docuSignRequestId, row.row.original.claimId, row.row.original.docuSignEnvelopeId)}
                        >
                            <SignatureIcon title={t('actionSign')} />
                        </Button>
                    )
                }
                {
                    !row.row.original.allowUpload &&
                    !row.row.original.allowESignature && (
                        <span className="action-icon" />
                    )
                }
                </div>
            )
        }, {
            Header: () => (<>{t('columnDate')}</>),
            accessor: columnHeaderProps.find(x => x.id === 'claimEventCreatedDateUTC')?.id,
            canSort: true,
            Cell: row => (
                <div className="datagrid-data-left">
                {
                    formatDate(row.value)
                }
                </div>
            )
        }, {
            Header: () => (<>{t('columnDescription')}</>),
            accessor: columnHeaderProps.find(x => x.id === 'clientEventDescription')?.id,
            canSort: true
        }, {
            Header: () => (<>{t('columnStatus')}</>),
            accessor: columnHeaderProps.find(x => x.id === 'claimEventStatusDescription')?.id,
            canSort: true
        }];

        const table = <Table
            columns={columns}
            data={gridData.searchResults} 
        />;

        let modal = props.documentTypeSelectOptions.hasLoaded && props.documentUploadModalShow &&
        (
            <ClaimDocumentUpload 
                documentTypeSelectOptions={props.documentTypeSelectOptions}
                documentType={documentType}
                claimEventId={claimEventId}
                refreshData={refreshData} 
                hideModal={props.hideUploadDocumentModal}
            />
        );

        return <div>{table}{modal}</div>;
    };

    let displayProcedurePacket = procedurePacket &&
    (
        <Button 
            className="button submit" 
            icon={<FilePdfIcon class="button-icon" title={t('actionViewProcedurePacket')}/>}
            type="primary"
            onClick={() => getProcedurePacketFile()}>
                {t('actionViewProcedurePacket')}
        </Button>
    );

    let title = <>
        {
            props.loadingDetails && 
            (
                <Waiting size='large' />
            )
        }
        {
            !props.loadingDetails && 
            (
                <>
                    <h2>
                        {props.isClaimActive ? t("title") : t("titleInactiveClaim")}
                    </h2>
                    {
                        !errorMsg && !loading && !loadingDocuSign && props.documentTypeSelectOptions.hasLoaded &&
                        (
                            <span>
                                <Button 
                                    className="button submit" 
                                    icon={<UploadIcon class="button-icon" title= {t('actionUpload')} />}
                                    type="primary"
                                    onClick={() => handleDocumentUpload(null, "", 0)}>
                                        {t('actionUpload')}
                                </Button>
                                &nbsp;
                                {displayProcedurePacket}
                            </span>
                        )
                    }
                    {
                        !errorMsg && !loading && !loadingDocuSign && !props.documentTypeSelectOptions.hasLoaded &&
                        (
                            <span>
                                {displayProcedurePacket}
                            </span>
                        )
                    }
                </>
            )
        }        
        <br /><br />
    </>;
    let contents = (errorMsg > '') ? <p><em>{errorMsg}</em></p> : (props.loadingDetails || loading || loadingDocuSign) ? <Waiting size='large' /> : renderDataGrid();
    return <div>{title}<br />{contents}</div>
};

export default ClaimTasks;