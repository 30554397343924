import * as React from "react";
const SvgBell = (props) => /* @__PURE__ */ React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", id: "bell_svg__Layer_1", "data-name": "Layer 1", viewBox: "0 0 250 250", width: "1em", height: "1em", ...props }, /* @__PURE__ */ React.createElement("defs", null, /* @__PURE__ */ React.createElement("style", null, ".bell_svg__cls-2{fill:none;stroke:#fff;stroke-miterlimit:10;stroke-width:11.26px}")), /* @__PURE__ */ React.createElement("path", { d: "M0 0h250v250H0z", style: {
  strokeWidth: 0,
  fill: "#abc7ca"
} }), /* @__PURE__ */ React.createElement("path", { d: "M58.43 186.31 135.94 250H250V132.63L159.95 58.4l-26.89-8.84h-14.84L78.63 82.87l-3.4 70.26v24.63z", style: {
  fill: "#8eb6be",
  strokeWidth: 0
} }), /* @__PURE__ */ React.createElement("path", { d: "M174.77 183.81H75.24V99.54c0-28.94 28.2-46.98 50.51-46.98 22.32 0 49.02 17.07 49.02 46.98z", className: "bell_svg__cls-2" }), /* @__PURE__ */ React.createElement("path", { d: "M125 41.1v10.15", style: {
  strokeWidth: "11.62px",
  fill: "none",
  stroke: "#fff",
  strokeLinecap: "round",
  strokeLinejoin: "round"
} }), /* @__PURE__ */ React.createElement("path", { d: "M62.31 183.81h125.38", style: {
  fill: "none",
  stroke: "#fff",
  strokeLinecap: "round",
  strokeLinejoin: "round",
  strokeWidth: "11.26px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M148.13 183.81s.84 25.09-22.71 25.09-23.55-25.09-23.55-25.09", className: "bell_svg__cls-2" }));
export default SvgBell;
