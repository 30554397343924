import { useContext, useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { NavAlertIcon, NavHomeIcon, NavInfoIcon, NavLogoutIcon, MenuIcon, NavProfileIcon } from './Icons';
import { AuthContext }  from '../App';
import {setUnreadAlertCountLabelFromApi} from '../util/AlertCountHack';

function SiteBanner(props) {
    const {accessToken, handleLogout, user} = useContext(AuthContext);
    //The first line sets the state hook up. The second sets it to be the reverse of what it is. So if it's true, then set it to false, and vice versa.
    const [isOpen, setIsOpen] = useState(false);
    const hide = () => setIsOpen(false);
    //add a function to show the menu on focus:
    const show = () => setIsOpen(true);
    const { t } = useTranslation('SiteBanner');

    useEffect(() => {
        setUnreadAlertCountLabelFromApi(user, accessToken);
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []
    );
    
    //if you click on a link it will toggle the menu being showing and hiding. If you click outside the menu it will hide it.
    function toggleNavbarItems() {
        setIsOpen(!isOpen);
        const navs = document.querySelectorAll('.topbuttons-items-col')
        navs.forEach(nav => nav.classList.toggle('topbuttons-items-show'));
    };

    function handleLogoutClick(event) {
        event.preventDefault();
        handleLogout();
    };

    return (
        <nav>
            <div id="topbuttons" className="column-container space-between">
                <div id="topbuttons-hamburger" onClick={toggleNavbarItems}>
                    <MenuIcon class="navbar-icon" title={t('menuMenu')} />
                </div>
                <div className="topbuttons-items-col">                   
                    <NavLink to="claims" className="topbtn" onClick={toggleNavbarItems} onBlur={hide} onFocus={show} title={t('menuClaims')}>
                        <NavHomeIcon class="navbar-icon" title={t('menuClaims')} />
                    </NavLink>
                    <NavLink to="alerts" className="topbtn" onClick={toggleNavbarItems} onBlur={hide} onFocus={show} title={t('menuAlerts')}>
                        <>
                            {/*CAUTION: See AlertCountHack.tsx before changing anything here */}
                            <span id="unreadAlertCount"></span>
                            <NavAlertIcon class="navbar-icon" title={t('menuAlerts')} />
                        </>
                    </NavLink>
                    <NavLink to="profile" className="topbtn" onClick={toggleNavbarItems} onBlur={hide} onFocus={show} title={t('menuProfile')}>
                        <NavProfileIcon class="navbar-icon" title={t('menuProfile')} />
                    </NavLink>
                    <NavLink to="faq" className="topbtn" onClick={toggleNavbarItems} onBlur={hide} onFocus={show} title={t('menuFaq')}>
                        <NavInfoIcon class="navbar-icon" title={t('menuFaq')} />
                    </NavLink>
                    <NavLink to="/." className="topbtn" onClick={(event) => handleLogoutClick(event)} title={t('menuLogout')}>
                        <NavLogoutIcon class="navbar-icon" title={t('menuLogout')} />
                    </NavLink>
                </div>
            </div>
        </nav>
    );
  }
  
  export default SiteBanner;