import * as React from "react";
const SvgGearmagnifyingglass = (props) => /* @__PURE__ */ React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", id: "gearmagnifyingglass_svg__Layer_1", "data-name": "Layer 1", viewBox: "0 0 250 250", width: "1em", height: "1em", ...props }, /* @__PURE__ */ React.createElement("defs", null, /* @__PURE__ */ React.createElement("style", null, ".gearmagnifyingglass_svg__cls-2{stroke-linecap:round;fill:none;stroke:#fff;stroke-linejoin:round;stroke-width:11.26px}")), /* @__PURE__ */ React.createElement("path", { d: "M0 0h250v250H0z", style: {
  strokeWidth: 0,
  fill: "#abc7ca"
} }), /* @__PURE__ */ React.createElement("path", { d: "M67.22 185.98 139.36 250H250V119.2l-54.84-46.89-20.77-11.36-8.99 2.7-18.82-7.22-4.94-9.05h-21.41l-4.52 8.67L92.4 67.41l-12.98-6.72-15.05 15.05 6.37 9.65-8.41 24.53-10.8 2.12v25.92h7.76l11.56 22.94z", style: {
  fill: "#8eb6be",
  strokeWidth: 0
} }), /* @__PURE__ */ React.createElement("circle", { cx: 125, cy: 125, r: 42.13, className: "gearmagnifyingglass_svg__cls-2" }), /* @__PURE__ */ React.createElement("path", { d: "M102.31 125c0-12.53 10.16-22.69 22.69-22.69", style: {
  strokeWidth: 6,
  strokeLinecap: "round",
  fill: "none",
  stroke: "#fff",
  strokeLinejoin: "round"
} }), /* @__PURE__ */ React.createElement("path", { d: "m147.57 160.53 29.86 29.84a9.15 9.15 0 0 0 12.95 0 9.15 9.15 0 0 0 0-12.95l-29.84-29.86", style: {
  fill: "none",
  stroke: "#fff",
  strokeLinejoin: "round",
  strokeWidth: "11.26px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M187.1 152.73c1.74-3.9 3.16-7.97 4.15-12.22.34-1.48 1.62-2.54 3.14-2.54h7.75c1.62 0 3.03-1.19 3.24-2.8.42-3.33.64-6.72.64-10.16s-.22-6.83-.64-10.16c-.2-1.61-1.61-2.8-3.24-2.8h-7.75c-1.52 0-2.79-1.07-3.14-2.54a67.8 67.8 0 0 0-8.66-20.77c-.67-1.07-.55-2.44.35-3.33l6.11-6.11c.95-.95 1.08-2.48.26-3.54a81.9 81.9 0 0 0-15.05-15.05c-1.07-.82-2.59-.69-3.54.26l-6.11 6.11c-.89.89-2.27 1.02-3.33.35a67.6 67.6 0 0 0-21.19-8.75c-1.23-.28-2.12-1.34-2.12-2.6v-8.67c0-1.35-.99-2.52-2.33-2.7a81.2 81.2 0 0 0-21.28 0c-1.34.18-2.33 1.35-2.33 2.7v8.67c0 1.26-.89 2.32-2.12 2.6a67.6 67.6 0 0 0-21.19 8.75c-1.07.67-2.44.55-3.33-.35l-6.11-6.11c-.95-.95-2.48-1.08-3.54-.26a81.9 81.9 0 0 0-15.05 15.05c-.82 1.07-.69 2.59.26 3.54l6.11 6.11c.89.89 1.02 2.27.35 3.33a67.6 67.6 0 0 0-8.75 21.19c-.28 1.23-1.34 2.12-2.6 2.12h-8.19c-1.62 0-3.03 1.19-3.24 2.8-.42 3.33-.64 6.72-.64 10.16s.22 6.83.64 10.16c.2 1.61 1.61 2.8 3.24 2.8h7.75c1.52 0 2.79 1.07 3.14 2.54 1.7 7.32 4.57 14.19 8.42 20.39.8 1.29.65 2.94-.42 4.02l-5.46 5.46c-1.14 1.14-1.3 2.98-.3 4.26 4.17 5.37 9 10.21 14.38 14.38 1.28.99 3.11.84 4.26-.3l5.46-5.46c1.07-1.07 2.73-1.22 4.02-.42 6.2 3.85 13.07 6.72 20.39 8.42 1.48.34 2.54 1.62 2.54 3.14v7.75c0 1.62 1.19 3.03 2.8 3.24 3.33.42 6.72.64 10.16.64s6.83-.22 10.16-.64c1.61-.2 2.8-1.61 2.8-3.24v-7.75c0-1.52 1.07-2.79 2.54-3.14 4.25-.99 8.32-2.41 12.22-4.15", className: "gearmagnifyingglass_svg__cls-2" }));
export default SvgGearmagnifyingglass;
