import { useContext, useEffect, useState } from 'react';
import { AuthContext, ProfileContext }  from '../App';
import { useTranslation } from 'react-i18next';
import Waiting from './Waiting';
import {get} from '../util/HttpRequest'
import ProfileUpdate from './ProfileUpdate';
import EmailUpdate from './EmailUpdate';
import EmailVerification from './EmailVerification';
import PasswordUpdate from './PasswordUpdate';
import { Button } from 'antd';
import { EditIcon, ResetIcon } from './Icons'
import {formatPhone} from '../util/Format';

class Length {
    min: number = 0;
    max: number = 0;
};

class MinCharacters {
    uppercase: number = 0;
    lowercase: number = 0;
    numeric: number = 0;
    special: number = 0;
}

class PasswordPolicy {
    id: string = "";
    name: string = "";
    description: string = "";
    hasLoaded: boolean = false;
    excludesProfileData: boolean = false;
    notSimilarToCurrent: boolean = false;
    excludesCommonlyUsed: boolean = false;
    length: Length = new Length();
    minCharacters: MinCharacters = new MinCharacters();
    minAgeDays: number|null = null;
    maxRepeatedCharacters: number|null = null;
    minUniqueCharacters: number|null = null;
}

function Profile(props) {
    const [updateProfileModal, setUpdateProfileModal] = useState(false);
    const [updateEmailModal, setUpdateEmailModal] = useState(false);
    const [verifyEmailModal, setVerifyEmailModal] = useState(false);
    const [updatePasswordModal, setUpdatePasswordModal] = useState(false);
    const [passwordPolicy, setPasswordPolicy] = useState(new PasswordPolicy());
    const {accessToken, extendSession} = useContext(AuthContext);
    const {languagePreference, userProfile} = useContext(ProfileContext);
    
    const { t } = useTranslation(["Profile", "EmailUpdate", "EmailVerification", "PasswordUpdate", "Language"]);
    
    useEffect (() => {
        getPasswordPolicyData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [languagePreference]);

    function toggleUpdateProfileModal(show: boolean) {
        extendSession();
        setUpdateProfileModal(show);
    }

    function toggleUpdateEmailModal(show: boolean) {
        extendSession();
        setUpdateEmailModal(show);
    }

    function toggleVerifyEmailModal(show: boolean) {
        extendSession();
        setVerifyEmailModal(show);
    }

    function toggleUpdatePasswordModal(show: boolean) {
        extendSession();
        setUpdatePasswordModal(show);
    }

    function getPasswordPolicyData() {
        extendSession();

        const url = new URL(import.meta.env.VITE_CCP_API_CCP + "/PasswordPolicy");
        console.log("Fetch Password Policy from " + url);

        get(url, accessToken)
        .then( response => {
            if (!response.ok) { throw response }
            return response.json() as Promise<PasswordPolicy>
        })
        .then( data => {
            data.hasLoaded = true;
            setPasswordPolicy(data);
        })
        .catch( error => {
            setPasswordPolicy(new PasswordPolicy());
        })
    };

    function renderData() {
        const map = new Map();
        map.set(t('lblEmail'), userProfile.email);
        //map.set(t('lblSmsConsent'), userProfile.sendSMS ? t('lblSmsConsentYes') : t('lblSmsConsentNo'));
        map.set(t('lblMobilePhone'), formatPhone(null, null, userProfile.mobilePhone, null));
        if (userProfile.allowESignature) {
            map.set(t('lblESignatureConsent'), userProfile.consentToESignature ? t('lblESignatureConsentYes') : t('lblESignatureConsentNo'));
        }
        //map.set(t('lblCommunicationLanguage'), t('Language:' + userProfile.languageCode.trim().toLowerCase()));
        //map.set(t('lblTimeZone'), userProfile.timeZone);

        return (
            <div className="row-container width-medium">
                {
                    Array.from(map).map((item, index) => {
                        return(<div key={index} className="column-container">
                            <div className={"key" + (index%2!==0?" even":"")} >{item[0]}</div>
                            <div className={"value" + (index%2!==0?" even":"")} >{item[1]}</div>
                        </div>);
                    })
                }
            </div>
        );
    };

    let title = <>
        <h2>{t("title")}</h2>
        {
            userProfile.hasLoaded &&
            (
                <span>
                    <Button 
                        className="button submit" 
                        tabIndex={0}
                        icon={<EditIcon class="button-icon" title={t('lblUpdate')} />}
                        type="primary"
                        onClick={() => toggleUpdateProfileModal(true)}>
                            {t('lblUpdate')}
                    </Button>
                </span>
            )
        }
        &nbsp;
        {
            userProfile.hasLoaded && 
            (
                <span>
                    <Button 
                        className="button submit" 
                        tabIndex={0}
                        icon={<ResetIcon class="button-icon" title={t('EmailUpdate:title')} / >}
                        type="primary"
                        onClick={() => toggleUpdateEmailModal(true)}>
                            {t('EmailUpdate:title')}
                    </Button>
                </span>
            )
        }
        &nbsp;
        {
            passwordPolicy.hasLoaded && 
            (
                <span>
                    <Button 
                        className="button submit" 
                        tabIndex={0}
                        icon={<ResetIcon class="button-icon" title= {t('PasswordUpdate:title')} />}
                        type="primary"
                        onClick={() => toggleUpdatePasswordModal(true)}>
                            {t('PasswordUpdate:title')}
                    </Button>
                </span>
            )
        }
        <br /><br />
    </>;
    
    let contents = !userProfile.hasLoaded ? <Waiting size='large' /> : renderData();
    return <div>
            {title}
            <br />
            {contents}
            {
                userProfile.hasLoaded && updateProfileModal &&
                (
                    <ProfileUpdate 
                        toggleUpdateProfileModal={toggleUpdateProfileModal}
                        allowSuccessNotification={true}
                    />
                )
            }
            {
                userProfile.hasLoaded && updateEmailModal &&
                (
                    <EmailUpdate 
                        toggleUpdateEmailModal={toggleUpdateEmailModal}
                        toggleVerifyEmailModal={toggleVerifyEmailModal}
                    />
                )
            }
            {
                userProfile.hasLoaded && verifyEmailModal &&
                (
                    <EmailVerification
                        toggleUpdateEmailModal={toggleUpdateEmailModal}    
                        toggleVerifyEmailModal={toggleVerifyEmailModal}
                        allowSuccessNotification={true}
                    />
                )
            }
            {
                passwordPolicy.hasLoaded && updatePasswordModal &&
                (
                    <PasswordUpdate 
                        passwordPolicy={passwordPolicy}
                        toggleUpdatePasswordModal={toggleUpdatePasswordModal}
                        allowSuccessNotification={true}
                    />
                )
            }
        </div>;
};

export default Profile;
