import { useContext, useState } from 'react';
import { AuthContext, ProfileContext }  from '../App';
import { useTranslation } from 'react-i18next';
import { Button, Modal } from 'antd';
import { PostResponse } from './Models';
//import {SelectFormField} from './FormFields';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import MaskedInput from 'react-text-mask';
import { SuccessNotification } from './Notification';
import { putForm } from '../util/HttpRequest';

function ProfileUpdate(props) {
    const phoneNumberMaskRegExp = new RegExp(`[() _-]+`, 'g');
    const [errorMsg, setErrorMsg] = useState("");
    const {accessToken, extendSession, user} = useContext(AuthContext);
    const {userProfile, refreshUserProfile} = useContext(ProfileContext);
    const { t } = useTranslation(["Profile", "Form", "Language"]);

    // const languageOptions = (import.meta.env.VITE_LANGUAGES || "")
    //     .split("|")
    //     .map(item => {
    //         return {value: item, label: t('Language:' + item.toLowerCase())}
    //     })
    // ;

    // const timeZoneOptions = Intl.supportedValuesOf('timeZone')
    //     .filter((item) => {
    //         return (
    //             item.startsWith("Africa") ||
    //             item.startsWith("America") ||
    //             item.startsWith("Asia") ||
    //             item.startsWith("Australia") ||
    //             item.startsWith("Europe")
    //         )
    //     })
    //     .map((item) => {
    //         const timeZoneOffset = new Intl.DateTimeFormat('en',{timeZone:item, timeZoneName:'shortOffset'}).formatToParts().find(part => part.type==='timeZoneName')!.value.replace('GMT', 'UTC');
    //         const timeZoneName = new Intl.DateTimeFormat('en',{timeZone:item, timeZoneName:'long'}).formatToParts().find(part => part.type==='timeZoneName')!.value
    //         return {value: item, label: `${item} - ${timeZoneName} (${timeZoneOffset})`};
    //     })
    // ;

    const ValidationSchema = Yup.object().shape({
        // mobilePhone: Yup.mixed()
        //     .when('sendSMS', {
        //         is: true,
        //         then: Yup.string().required(t("lblMobilePhoneRequired"))
        //             .min(10, t("lblMobilePhoneInvalid"))
        //             .max(10, t("lblMobilePhoneInvalid")),
        //         otherwise: Yup.string()
        //             .min(10, t("lblMobilePhoneInvalid"))
        //             .max(10, t("lblMobilePhoneInvalid"))
        //     })
        mobilePhone: Yup.string()
            .min(10, t("lblMobilePhoneInvalid"))
            .max(10, t("lblMobilePhoneInvalid"))
    });

    // function calculateTimezoneOffset(timezone: string) {
    //     return parseFloat(new Intl.DateTimeFormat('en',{timeZone:timezone, timeZoneName:'shortOffset'})
    //         .formatToParts()
    //         .find(part => part.type==='timeZoneName')!.value
    //         .replace('GMT', '')
    //         .replace(':00', '')
    //         .replace(':15', '.25')
    //         .replace(':30', '.50')
    //         .replace(':45', '.75')
    //     );
    // }
      
    function handleSubmit(values, setSubmitting) {
        setSubmitting(false);   // MUST invoke this before updating any parent props or the form submit happens twice!
        extendSession();

        const url = new URL(import.meta.env.VITE_CCP_API_CCP + "/UserProfile");
        const formData = new FormData();
        formData.append("SSOUserId", user.id);
        // formData.append("SendSMS", values.sendSMS);
        if (values.mobilePhone) {
            formData.append("MobilePhone", values.mobilePhone);
        }
        // formData.append("LanguageCode", values.languageCode);
        // formData.append("TimeZone", values.timeZone);
        // formData.append("TimeZoneOffset", calculateTimezoneOffset(values.timeZone).toString());
        formData.append("ConsentToESignature", values.eSignatureConsent);
        console.log("Update User Profile to " + url);

        putForm(url, accessToken, formData)
        .then((response) => {
            if (!response.ok) { throw new Error(response.status + " - " + response.statusText)}
            return response.json() as Promise<PostResponse>;
        })
        .then(response => {
            if (response.success) {
                props.toggleUpdateProfileModal(false);
                refreshUserProfile();
                if (props.allowSuccessNotification) {
                    SuccessNotification(t('lblUpdateSuccessTitle'), t('lblUpdateSuccessBody'));
                }
            }
            else {
                setErrorMsg(t('lblUpdateFailure'));
            }
        })
        .catch(() => {
            setErrorMsg(t('lblUpdateFailure'));
        })
    }

    function renderForm() {
        return (
            <Formik
                initialValues ={{
                    // sendSMS: userProfile.sendSMS,
                    mobilePhone: userProfile.mobilePhone??'',
                    // languageCode: userProfile.languageCode,
                    // timeZone: userProfile.timeZone,
                    eSignatureConsent: userProfile.consentToESignature
                }}
                validationSchema={ValidationSchema}
                onSubmit={(values, { setSubmitting }) => {
                    handleSubmit(values, setSubmitting);
                }}
            >
            {
                ({ values, isSubmitting, setFieldValue, submitForm }) => (
                    <Form>
                        {/* <label htmlFor="sendSMS">{t('lblSmsConsent')}</label>
                        <br />
                        <Field id="sendSMS" name="sendSMS" type="checkbox" checked={values.sendSMS}></Field>
                        &nbsp;&nbsp;{t('lblSmsLegalese')}<br />
                        <a href="https://www.bbinsurance.com/legal-notices" target="_blank" rel="noopener noreferrer">{t('Layout:lblLegal')}</a>
                        &nbsp;/&nbsp;
                        <a href="https://www.bbinsurance.com/privacy-statement" target="_blank" rel="noopener noreferrer">{t('Layout:lblPrivacy')}</a>
                        <br /><br /> */}
                        <>
                            <label htmlFor="mobilePhone">{t('lblMobilePhone')}</label>
                            <br />
                            <Field name="mobilePhone">
                            {
                                ({ field }) => {
                                return <MaskedInput mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                                    {...field}
                                    id="mobilePhone"
                                    className="input"
                                    data-lpignore="true"
                                    placeholder={t('lblMobilePhone')} 
                                    onChange={(event) => {
                                        setFieldValue(field.name, event.target.value.replace(phoneNumberMaskRegExp, ''));
                                    }} />
                                }
                            }
                            </Field>
                            <br />
                            <ErrorMessage name="mobilePhone" component="div" className="error" />
                            <br /> 
                        </>
                        {/* <label id="languageCodeLabel">{t('lblCommunicationLanguage')}</label>
                        <br />
                        <Field name="languageCode" ariaLabelledBy="languageCodeLabel" component={SelectFormField} options={languageOptions} />
                        <ErrorMessage name="languageCode" component="div" className="error" />
                        <br />
                        <label id="timeZoneLabel">{t('lblTimeZone')}</label>
                        <br />
                        <Field name="timeZone" ariaLabelledBy="timeZoneLabel" component={SelectFormField} options={timeZoneOptions} />
                        <ErrorMessage name="timeZone" component="div" className="error" />
                        <br /> */}
                        { 
                            userProfile.allowESignature ? 
                            (
                                <div>
                                    <label htmlFor="eSignatureConsent">{t('lblESignatureConsent')}</label>
                                    <br />
                                    <Field id="eSignatureConsent" name="eSignatureConsent" type="checkbox" checked={values.eSignatureConsent}></Field>
                                    &nbsp;&nbsp;{t('lblESignatureLegalese')}
                                    <br /><br />
                                </div>
                            ) : <div />
                        }
                        <Button type="default" className="button" onClick={() => props.toggleUpdateProfileModal(false)}>{t('Form:btnCancel')}</Button>
                        &nbsp;&nbsp;
                        <Button type="primary" className="button submit" disabled={isSubmitting} onClick={submitForm}>{t('lblUpdate')}</Button>
                        <br /><br />
                        <div className="error">{errorMsg}</div>
                    </Form>
                )
            }
            </Formik>
        );
    }

    return (
        <Modal open={true} 
               title={t('lblUpdate')} 
               footer={null}
               onCancel={() => props.toggleUpdateProfileModal(false)}
               centered={true}
               destroyOnClose={true}
        >
            <div>
                <hr />
                {renderForm()}
            </div>
        </Modal>
    );
}

export default ProfileUpdate;
