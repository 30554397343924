import * as React from "react";
const SvgPencil = (props) => /* @__PURE__ */ React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", id: "pencil_svg__Layer_1", "data-name": "Layer 1", viewBox: "0 0 250 250", width: "1em", height: "1em", ...props }, /* @__PURE__ */ React.createElement("defs", null, /* @__PURE__ */ React.createElement("style", null, ".pencil_svg__cls-4{stroke-width:0;fill:#fff}")), /* @__PURE__ */ React.createElement("path", { d: "M0 0h250v250H0z", style: {
  fill: "#abc7ca",
  strokeWidth: 0
} }), /* @__PURE__ */ React.createElement("path", { d: "m46.9 202.79 75.06 47.43H250V88.05l-47.02-40.61-83.92 8.41h-14.92l-33.6 33.53-.3 67.21-1.96 24.38z", style: {
  fill: "#8eb6be",
  strokeWidth: 0
} }), /* @__PURE__ */ React.createElement("path", { d: "M52.71 52.71h144.57v144.57H52.71z", style: {
  stroke: "#fff",
  strokeMiterlimit: 10,
  strokeWidth: "11.26px",
  fill: "#8eb6be"
} }), /* @__PURE__ */ React.createElement("path", { d: "m90.79 144.19-5.6 18.76 18.49-5.95zM143.266 90.957l12-12 13.258 13.258-12 12zM93.89 140.329l45.864-45.863 13.258 13.258-45.863 45.863z", className: "pencil_svg__cls-4" }));
export default SvgPencil;
