import { Route, Routes } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AlertIcon, FaqIcon, ProfileIcon, UnknownIcon } from './Icons';

function OtherNavbar(props) {
    const pageNotFound = "404";
    const { t } = useTranslation(["SiteBanner", "App"]);
    const otherNavItems = [
        import.meta.env.VITE_APP_TAB_ALERTS||"",
        import.meta.env.VITE_APP_TAB_PROFILE||"",
        import.meta.env.VITE_APP_TAB_FAQ||"",
        pageNotFound
    ];

    function renderBannerIcon(key, title) {
        if (key === import.meta.env.VITE_APP_TAB_ALERTS) {
            return <AlertIcon class="banner-icon" title={title} />
        } 
        else if (key === import.meta.env.VITE_APP_TAB_PROFILE) {
            return <ProfileIcon class="banner-icon" title={title} />
        }
        else if (key === import.meta.env.VITE_APP_TAB_FAQ) {
            return <FaqIcon class="banner-icon" title={title} />
        }
        else {
            return <UnknownIcon class="banner-icon" />;
        }
    }
    
    const items = 
        <div id="navcontainer" className="column-container space-between nav-container">
            <div>&nbsp;</div>
            <div>&nbsp;</div>
        </div>
    ;

    let pageTitle = 
        <div id="bannertitle" className="column-container">
            <Routes>
                <Route key="/" path="/" element={<></>} />
                {
                    otherNavItems.map((item, index) => {
                        const title = (item !== pageNotFound ? t('menu' + item.charAt(0).toUpperCase() + item.substring(1)) : t("App:lbl" + item));
                        const path = (item !== pageNotFound ? item : "*");
                        return <Route key={item} path={path}
                            element={
                                <>
                                    {renderBannerIcon(item, title)}
                                    <h1 className="row-container center">{title}</h1>
                                </>
                            }
                        />;
                    })
                }
            </Routes>
        </div>
    ;

    let contents = 
        <div className="row-container">
            { items }
            { pageTitle }
        </div>
    ;
    
    return (contents);
};

export default OtherNavbar;