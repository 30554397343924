import Icon, {
  BellOutlined, BugOutlined, CaretDownOutlined, CaretLeftOutlined, CaretRightOutlined, CaretUpOutlined,
  CheckCircleOutlined, DeleteOutlined, DoubleLeftOutlined,
  DoubleRightOutlined, DownCircleOutlined, EditOutlined, ExclamationCircleOutlined, EyeOutlined,
  FileOutlined, FilePdfOutlined, FormOutlined, GlobalOutlined, HomeOutlined, InfoCircleOutlined,
  LeftOutlined, LogoutOutlined, MailOutlined, MenuOutlined, MessageOutlined, MinusOutlined,
  PhoneOutlined, PlusOutlined, QuestionOutlined, QuestionCircleOutlined, RetweetOutlined, RightOutlined, SafetyCertificateOutlined,
  UpCircleOutlined, UploadOutlined, UserOutlined
} from '@ant-design/icons';
import Bell from '../images/bell.svg?react';
import Checklist from '../images/checklist.svg?react';
import Document from '../images/document.svg?react';
import GearMagnifyingGlass from '../images/gearmagnifyingglass.svg?react';
import GearMagnifyingGlassPlain from '../images/gearmagnifyingglassplain.svg?react';
import Helmet from '../images/helmet.svg?react';
import Logo from '../images/mip_logo.svg?react';
import MoneyTransaction from '../images/moneytransaction.svg?react';
import Person from '../images/person.svg?react';
import Pencil from '../images/pencil.svg?react';
import Question from '../images/question.svg?react';


function getClassName(className: string) {
  return (className > "" ? className : "medium-icon");
}

export function AlertIcon(props) {
  return (<Icon component={Bell} className={getClassName(props.class)} aria-label={props.title} title={props.title} />);
}

export function CaretDownIcon(props) {
  return (<CaretDownOutlined className={getClassName(props.class)} aria-label={props.title} title={props.title} />);
}

export function CaretLeftIcon(props) {
  return (<CaretLeftOutlined className={getClassName(props.class)} aria-label={props.title} title={props.title} />);
}

export function CaretRightIcon(props) {
  return (<CaretRightOutlined className={getClassName(props.class)} aria-label={props.title} title={props.title} />);
}

export function CaretUpIcon(props) {
  return (<CaretUpOutlined className={getClassName(props.class)} aria-label={props.title} title={props.title} />);
}

export function CaretUpDownIcon(props) {
  return (
    <span className={getClassName(props.class)} aria-label={props.title} title={props.title}>
      <CaretUpOutlined aria-label={props.title} /><CaretDownOutlined aria-label={props.title} />
    </span>
  );
}

export function CheckIcon(props) {
  return (<CheckCircleOutlined className={getClassName(props.class)} aria-label={props.title} title={props.title} />);
}

export function CollapseIcon(props) {
  return (<DownCircleOutlined className={getClassName(props.class)} aria-label={props.title} title={props.title} />);
}

export function CommunicationIcon(props) {
  return (<MailOutlined className={getClassName(props.class)} aria-label={props.title} title={props.title} />);
}

export function ContractorsIcon(props) {
  return (<Icon component={Helmet} className={getClassName(props.class)} aria-label={props.title} title={props.title} />);
}

export function DebugIcon(props) {
  return (<BugOutlined className={getClassName(props.class)} aria-label={props.title} title={props.title} />);
}

export function DeleteIcon(props) {
  return (<DeleteOutlined className={getClassName(props.class)} aria-label={props.title} title={props.title} />);
}

export function DocumentsIcon(props) {
  return (<Icon component={Document} className={getClassName(props.class)} aria-label={props.title} title={props.title} />);
}

export function DoubleLeftIcon(props) {
  return (<DoubleLeftOutlined className={getClassName(props.class)} aria-label={props.title} title={props.title} />);
}

export function DoubleRightIcon(props) {
  return (<DoubleRightOutlined className={getClassName(props.class)} aria-label={props.title} title={props.title} />);
}

export function EditIcon(props) {
  return (<FormOutlined className={getClassName(props.class)} aria-label={props.title} title={props.title} />);
}

export function ExclamationIcon(props) {
  return (<ExclamationCircleOutlined className={getClassName(props.class)} aria-label={props.title} title={props.title} />);
}

export function ExpandIcon(props) {
  return (<UpCircleOutlined className={getClassName(props.class)} aria-label={props.title} title={props.title} />);
}

export function EyeIcon(props) {
  return (<EyeOutlined className={getClassName(props.class)} aria-label={props.title} title={props.title} />);
}

export function FileIcon(props) {
  return (<FileOutlined className={getClassName(props.class)} aria-label={props.title} title={props.title} />);
}

export function FilePdfIcon(props) {
  return (<FilePdfOutlined className={getClassName(props.class)} aria-label={props.title} title={props.title} />);
}

export function FaqIcon(props) {
  return (<Icon component={Question} className={getClassName(props.class)} aria-label={props.title} title={props.title} />);
}

export function GlobeIcon(props) {
  return (<GlobalOutlined className={getClassName(props.class)} aria-label={props.title} title={props.title} />);
}

export function InspectionsIcon(props) {
  return (<Icon component={GearMagnifyingGlass} className={getClassName(props.class)} aria-label={props.title} title={props.title} />);
}

export function LeftIcon(props) {
  return (<LeftOutlined className={getClassName(props.class)} aria-label={props.title} title={props.title} />);
}

export function MenuIcon(props) {
  return (<MenuOutlined className={getClassName(props.class)} aria-label={props.title} title={props.title} />);
}

export function MessageIcon(props) {
  return (<MessageOutlined className={getClassName(props.class)} aria-label={props.title} title={props.title} />);
}

export function MinusIcon(props) {
  return (<MinusOutlined className={getClassName(props.class)} aria-label={props.title} title={props.title} />);
}

export function MIPLogoIcon(props){
  return (<Icon component={Logo}  className={getClassName(props.class)} aria-label={props.title} title={props.title} />);
}

export function NavAlertIcon(props) {
  return (<BellOutlined className={getClassName(props.class)} aria-label={props.title} title={props.title} />);
}

export function NavHomeIcon(props) {
  return (<HomeOutlined className={getClassName(props.class)} aria-label={props.title} title={props.title} />);
}

export function NavInfoIcon(props) {
  return (<InfoCircleOutlined className={getClassName(props.class)} aria-label={props.title} title={props.title} />);
}

export function NavProfileIcon(props) {
  return (<UserOutlined className={getClassName(props.class)} aria-label={props.title} title={props.title} />);
}

export function NavLogoutIcon(props) {
  return (<LogoutOutlined className={getClassName(props.class)} aria-label={props.title} title={props.title} />);
}

export function PhoneIcon(props) {
  return (<PhoneOutlined className={getClassName(props.class)} aria-label={props.title} title={props.title} />);
}

export function PlusIcon(props) {
  return (<PlusOutlined className={getClassName(props.class)} aria-label={props.title} title={props.title} />);
}

export function ProfileIcon(props) {
  return (<Icon component={Person} className={getClassName(props.class)} aria-label={props.title} title={props.title} />);
}

export function QuestionIcon(props) {
  return (<QuestionCircleOutlined className={getClassName(props.class)} aria-label={props.title} title={props.title} />);
}

export function ResetIcon(props) {
  return (<RetweetOutlined className={getClassName(props.class)} aria-label={props.title} title={props.title} />);
}

export function RightIcon(props) {
  return (<RightOutlined className={getClassName(props.class)} aria-label={props.title} title={props.title} />);
}

export function RegistrationIcon(props) {
  return (<Icon component={Pencil} className={getClassName(props.class)} aria-label={props.title} title={props.title} />);
}

export function RequestInspectionIcon(props) {
  return (<Icon component={GearMagnifyingGlassPlain} className={getClassName(props.class)} aria-label={props.title} title={props.title} />);
}

export function SignatureIcon(props) {
  return (<EditOutlined className={getClassName(props.class)} aria-label={props.title} title={props.title} />);
}

export function TasksIcon(props) {
  return (<Icon component={Checklist} className={getClassName(props.class)} aria-label={props.title} title={props.title} />);
}

export function TransactionsIcon(props) {
  return (<Icon component={MoneyTransaction} className={getClassName(props.class)} aria-label={props.title} title={props.title} />);
}

export function UnknownIcon(props) {
  return (<QuestionOutlined className={getClassName(props.class)} aria-label={props.title} title={props.title} />);
}

export function UploadIcon(props) {
  return (<UploadOutlined className={getClassName(props.class)} aria-label={props.title} title={props.title} />);
}

export function VerificationIcon(props) {
  return (<SafetyCertificateOutlined className={getClassName(props.class)} aria-label={props.title} title={props.title} />);
}




