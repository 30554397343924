import { useTranslation } from 'react-i18next';
import FaqBody from './FaqBody';

function Faq(props) {
    const {t} = useTranslation("Faq");
    
    let title = <h2>{t("title")}</h2>;
    return <div>{title}<br /><FaqBody /></div>;
};

export default Faq;