import { useCallback, useEffect, useState } from 'react';
import authClient from './ping/api';
import _ from "lodash";
import {ClearUserSession, ExecuteUserLogout}  from './UserSession';
import {CacheKey, CacheTTL}  from '../util/Cache';
import { postForm } from '../util/HttpRequest';

class Hashes {
  access_token?: string;
  error?: string;
  error_description?: string;
  expires_in?: string;
  id_token?: string;
  scope?: string;
  state?: string;
  token_type?: string;
};

export class User {
  email: string = "";
  id: string = "";
  name: string = "";
  nickname: string = "";
  constructor(Email, Id, Name, Nickname) {
    this.email = Email;
    this.id = Id;
    this.name = Name;
    this.nickname = Nickname;
  }
};

/**
 * React component for managing the return entry point of the implicit OAuth 2.0 flow and is expecting "access_token", "id_token" or "code" in a redirect uri.
 * The user will be redirected to this point based on the redirect_uri in config.js - the URL that specifies the return entry point of this application.
 */
 function usePing () {
  const [accessToken, setAccessToken] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [idToken, setIdToken] = useState(undefined);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(new User("", "", "", ""));
  let timeoutId: NodeJS.Timeout|undefined = undefined;

  useEffect(() => {

    let auto_login: boolean = false;
    let now = new Date();
    let access_token = sessionStorage.getItem(CacheKey.AUTH.ACCESS_TOKEN) || '';
    let token_inactive = new Date(sessionStorage.getItem(CacheKey.AUTH.TOKEN_INACTIVE) || now.getTime());
    let token_expired = new Date(sessionStorage.getItem(CacheKey.AUTH.TOKEN_EXPIRED) || now.getTime());
    let hashes = new Hashes();
    hashes = authClient.parseHash();

    if (hashes.error && hashes.error_description) {
      setErrorMessage(hashes.error + ': ' + hashes.error_description);
      return;
    }

    if (access_token > '' && 
        token_inactive > now && 
        token_expired > now
      ) {
        // we are already in a session
        refreshUserInfo(access_token, false);
        setAccessToken(access_token);
        //setTokenInactive(token_inactive);
        //setTokenExpired(token_expired);
        setIsAuthenticated(true);
        setLoading(false);
        return;
    }

    const stateMatch = window.location.href.match('[?#&]state=([^&]*)');
    if (stateMatch && !stateMatch[1] &&
        stateMatch[1].toLowerCase() !== sessionStorage.getItem(CacheKey.AUTH.STATE)?.toLowerCase()) {  
        setErrorMessage("State parameter mismatch. ");
      ClearUserSession();
      return;
    }

    const codeMatch = window.location.href.match('[?#&]code=([^&]*)');
    // Implicit flow: access token is present in URL
    if (hashes.access_token) {
      refreshUserInfo(hashes.access_token, true);
      
      token_inactive.setTime(token_inactive.getTime() + CacheTTL.INACTIVITY_TIMEOUT);
      token_expired.setTime(token_expired.getTime() + (Number(hashes.expires_in) * 1000));

      sessionStorage.setItem(CacheKey.AUTH.TOKEN_INACTIVE, token_inactive.toString());
      sessionStorage.setItem(CacheKey.AUTH.TOKEN_EXPIRED, token_expired.toString());
      sessionStorage.setItem(CacheKey.AUTH.ACCESS_TOKEN, hashes.access_token);

      setAccessToken(hashes.access_token);
      //setTokenInactive(token_inactive);
      //setTokenExpired(token_expired);
      setIsAuthenticated(true);
      auto_login = false;
    }
    // Authorization code flow: access code is present in URL
    else if (codeMatch && codeMatch[1]) {
      authClient.getAccessToken(codeMatch[1])
      .then(token => {
        verifyToken(token.id_token);
        refreshUserInfo(token.access_token, true);

        token_inactive.setTime(token_inactive.getTime() + CacheTTL.INACTIVITY_TIMEOUT);
        token_expired.setTime(token_expired.getTime() + (Number(hashes.expires_in) * 1000));

        sessionStorage.setItem(CacheKey.AUTH.TOKEN_INACTIVE, token_inactive.toString());
        sessionStorage.setItem(CacheKey.AUTH.TOKEN_EXPIRED, token_expired.toString());
        sessionStorage.setItem(CacheKey.AUTH.ACCESS_TOKEN, hashes.access_token || '');
        
        setIdToken(token.id_token);
        setAccessToken(token.access_token);
        // setTokenInactive(token_inactive);
        // setTokenExpired(token_expired);
        setIsAuthenticated(true);
        auto_login = false;
      })
      .catch(error => {
        setErrorMessage("Couldn't get an access token. " + _.get(error,
              'error_description', _.get(error, 'message', '')))
      });
    }
    
    setLoading(false);

    // Replace current URL without adding it to history entries
    const url_pathname = sessionStorage.getItem(CacheKey.URL_PATHNAME) || '/';
    const url_search = sessionStorage.getItem(CacheKey.URL_SEARCH) || '';
    if (window.location.hash > '') {
      window.history.replaceState({}, document.title, url_pathname + url_search);
    }

    if (hashes.access_token) {
      sessionStorage.removeItem(CacheKey.URL_PATHNAME);
      sessionStorage.removeItem(CacheKey.URL_SEARCH);
    }
    else {
      sessionStorage.setItem(CacheKey.URL_PATHNAME, window.location.pathname);
      sessionStorage.setItem(CacheKey.URL_SEARCH, window.location.search);
    }

    let logout = Number(sessionStorage.getItem(CacheKey.AUTH.LOGOUT)) || 0;
    if (!!auto_login && logout === 0) {
      handleLogin();
    }
    else {
      if (logout > 1) {
        sessionStorage.setItem(CacheKey.AUTH.LOGOUT, (--logout).toString());
      }
      else {
        sessionStorage.removeItem(CacheKey.AUTH.LOGOUT);
      }
    }
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function postLogin(userId, token) {
    const url = new URL(import.meta.env.VITE_CCP_API_CCP + "/Login");
    const formData = new FormData();
    formData.append("userId", userId);
    console.log("Post Login at " + url);

    postForm(url, token, formData)
    .catch( error => {
      console.log("postLogin() => failed with error: " + error.toString());
    })
  };

  const handleLogin = async () => {
    setLoading(true);
    ClearUserSession();
    const state = authClient.generateRandomValue();
    const nonce = authClient.generateRandomValue();
    sessionStorage.setItem(CacheKey.AUTH.STATE, state);
    sessionStorage.setItem(CacheKey.AUTH.NONCE, nonce);
    authClient.authorize(state, nonce);
    setLoading(false);
  }

  const handleLogout = async () => {
    // ClearUserSession();
    // if (idToken) {
    //   authClient.signOff(idToken, sessionStorage.getItem(CacheKey.AUTH.STATE));
    // }
    // else {
    //   sessionStorage.setItem(CacheKey.AUTH.LOGOUT, (import.meta.env.MODE === "development-local" ? "2" : "1"));
    //   window.location.assign(import.meta.env.VITE_PINGONE_LOGOUT_REDIRECT_URI || "");
    // }
    ExecuteUserLogout(false);
  }

  function verifyToken(idToken) {
    authClient.verifyIdToken(idToken, {
          nonce: sessionStorage.getItem(CacheKey.AUTH.NONCE),
          maxAge: (import.meta.env.VITE_PINGONE_MAX_AGE || 0)
        }
    )
    // .then(result => {
    //   setIdTokenJson(result);
    // })
    .catch(error => {
      setErrorMessage("Id token verification failed. " + _.get(error,
            'error_description', _.get(error, 'message', error)));
    })
  }

  function refreshUserInfo(token, justLoggedIn=false) {
    authClient.getUserInfo(token)
    .then(response => {
      const u = new User((response.email || ""),
        (response.sub || ""),
        //(response.given_name || "") + " " + (response.family_name || ""),
        (response.name || ""),
        (response.preferred_username || "")
      );
      setUser(u);
      if (justLoggedIn) {
        postLogin(u.id, token);
      }
    })
    .catch(error => {
      const errorDetail = _.get(error, 'details[0].code', null).toString();
      if (errorDetail.toUpperCase() === 'INVALID_VALUE') {
      //if (_.isEqual(errorDetail, 'INVALID_VALUE')) {
        if (_.get(error, 'details[0].message', null).includes(
            "Access token expired")) {
              setErrorMessage('Your access token is expired. Please login again.');
        } else {
          setErrorMessage(_.get(error, 'details[0].message', null));
        }
      } else if (errorDetail) {
        setErrorMessage(errorDetail + _.get(error, 'details[0].message', null));
      } else if (_.get(error, 'error', null) || _.get(error,
          'error_description', null)) {
          setErrorMessage(_.get(error, 'error', null) + ': ' + _.get(error, 'error_description', null));
      }
      return Promise.reject(error);
    })
  }

  function forceLogout() {
      sessionStorage.setItem(CacheKey.URL_PATHNAME, window.location.pathname);
      sessionStorage.setItem(CacheKey.URL_SEARCH, window.location.search);
      console.log("Forcing logout");
      handleLogout();
  }

  function authenticate(extendSession = false) {
    if (accessToken === '') return false;
    
    const now = new Date();
    const token_expired = new Date(sessionStorage.getItem(CacheKey.AUTH.TOKEN_EXPIRED) || 0);
    const token_inactive = new Date(sessionStorage.getItem(CacheKey.AUTH.TOKEN_INACTIVE) || 0);
    if (now > token_inactive || now > token_expired) return false;
    
    if (extendSession) {
      let new_token_inactive = new Date(now.getTime() + CacheTTL.INACTIVITY_TIMEOUT);
      new_token_inactive = (new_token_inactive > token_expired ? token_expired : new_token_inactive);
      sessionStorage.setItem(CacheKey.AUTH.TOKEN_INACTIVE, new_token_inactive.toString());
      console.log("User session extended until " + new_token_inactive.toISOString());
      
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      timeoutId = setTimeout(checkSession, CacheTTL.INACTIVITY_TIMEOUT);
    }

    return true;
  }

  const extendSession = useCallback(
    () => {
      if (!authenticate(true)) {
        forceLogout();
      }
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [accessToken]
  );

  const checkSession = useCallback(
    () => {
      if (!authenticate(false)) {
        forceLogout();
      }
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return {
    accessToken, 
    errorMessage,
    extendSession,
    handleLogin, 
    handleLogout,
    isAuthenticated,
    loading,
    user
  };
}

export default usePing;
